import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [{
      path: '/',
      component: () => import("@/views/Home"),
    },
    {
      path: '/home',
      name: 'home',
      component: () => import("@/views/Home"),
    },
    {
      path: '/h5map',
      name: 'h5map',
      component: () => import("@/views/H5Map"),
    },
    {
      path: '/s',
      name: 's',
      component: () => import("@/views/AxicheCoupon"),
    },
    {
      path: '/sightmap',
      name: 'sightmap',
      component: () => import("@/views/SightMap"),
    },
    {
      path: '/routemap',
      name: 'routemap',
      component: () => import("@/views/RouteMap"),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import("@/views/Login"),
    },
    {
      path: '/regwechat',
      name: 'regwechat',
      component: () => import("@/views/RegWechat"),
    },
    {
      path: '/content',
      name: 'content',
      component: () => import("@/views/Content"),
    },

    //-------------------sightcompany

    {
      path: "/sightcompany",
      name: "sightcompany",
      redirect: '/sightcompany/order',
      component: () => import("@/sightcompany/SightCompany"),
      children: [{
          path: "order",
          name: "order",
          component: () => import("@/sightcompany/Order"),
        },
        {
          path: "servicesight",
          name: "servicesight",
          component: () => import("@/sightcompany/ServiceSight"),
        },
        {
          path: "tickets",
          name: "tickets",
          component: () => import("@/sightcompany/Tickets"),
        },
        {
          path: "walletsight",
          name: "walletsight",
          component: () => import("@/sightcompany/WalletSight"),
        },
        {
          path: "sightadmin",
          name: "sightadmin",
          component: () => import("@/sightcompany/SightAdmin"),
        },
        {
          path: "commentsight",
          name: "commentsight",
          component: () => import("@/sightcompany/CommentSight"),
        },
        {
          path: "companyinfosight",
          name: "companyinfosight",
          component: () => import("@/sightcompany/CompanyInfoSight"),
        },
        {
          path: "membersight",
          name: "membersight",
          component: () => import("@/sightcompany/MemberSight"),
        },
        {
          path: "messagesight",
          name: "messagesight",
          component: () => import("@/sightcompany/MessageSight"),
        },
        {
          path: "validphonesightcompany",
          name: "validphonesightcompany",
          component: () => import("@/sightcompany/ValidphoneSightCompany"),
        },
        {
          path: "setphonesightcompany",
          name: "setphonesightcompany",
          component: () => import("@/sightcompany/SetphoneSightCompany"),
        },
      ]
    },

  ]
});