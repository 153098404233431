<template>
  <!-- //链接跳转小程序的中专页面 -->
  <div style="text-align: center; background: #fff">
    <h1 style="padding-top: 12vh; color: #555">
      <p style="font-size: 20px">即将进入</p>
      <div>十万八小程序</div>
    </h1>
    <img :src="logo" width="70px" height="70px" alt style="padding: 10vh 0 20vh 0" @click="goIndex" />

    <div>
      <el-button type="success" @click="openWechat" round>点击进入</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      id: "",
      urlsheme: "",
      logo: require("../assets/logo.png")
    };
  },

  created() {
    this.onLoad();
  },

  methods: {
    onLoad() {
      window.location.href =
        "weixin://dl/business/?appid=wx52a56b2c20b94fda&path=pages/index/index";
    },

    openWechat() {
      window.location.href =
        "weixin://dl/business/?appid=wx52a56b2c20b94fda&path=pages/index/index";
    },

    goIndex() {
      window.location.href =
        "weixin://dl/business/?appid=wx52a56b2c20b94fda&path=pages/index/index";
    }
  }
};
</script>

<style>
</style>
